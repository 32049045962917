$mainYellow: #ffd53f;
$boldFont: PingFangSC-Semibold,
"Microsoft YaHei";

.container {
  max-width: 1080px;
  padding: 0;
}

.content-wrap {
  overflow: hidden;
}

.content-wrap-box {
  padding-top: 30px;
}

.left_container {
  float: left;
  width: 720px;
  min-height: 80vh;
}

.right_container {
  float: right; // margin-top: 13px;
  width: 300px;
}

// 添加真相大会活动banner --start
.header-banner {
  display: none;
  width: 100%;
  height: 80px;
  background-color: lightgray;
  // background-image: url("/imgs/advertisement/qiantou_2019_03_02.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;

  .closeAd-box {
    width: 54px;
    height: 25px;
    background: rgba(39, 41, 46, 0.4);
    position: absolute;
    top: 0px;
    right: 0px;

    .closeAd {
      font-size: 12px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 25px;
      text-align: center;

      &::before {
        content: '';
        display: inline-block;
        width: 11px;
        height: 11px;
        background: url('/imgs/close_icon.png') no-repeat;
        background-size: contain;
        position: relative;
        top: 2px;
        left: -3px;
      }
    }
  }
}

// ----------------- 轮播样式调整 --------------------
.main_focus_col {
  padding: 0 5px;

  .carousel {
    margin-bottom: 0;

    .carousel-inner {
      .item {
        height: 320px;

        a img {
          height: 100%;
        }
      }
    }

    .carousel-indicators {
      right: 12px;
      left: auto;
      width: auto;
      bottom: -5px;
    }

    .carousel-indicators li {
      transition: 0.3s all ease-in-out;
      border: 0;
      width: 6px;
      height: 6px;
      margin: 2px;
      background: rgba(255, 255, 255, 0.6);
    }

    .carousel-indicators .active {
      width: 20px;
      height: 6px;
      border: 0;
      margin: 2px;
      background-color: #ffd53f;
    }
  }

  a[href="#main_focus_img"] {
    line-height: 320px;
    font-size: 60px;
  }

  .main_focus {
    // float: left;
    overflow: hidden;

    &>div {
      position: relative; // width: 580px;
      width: 100%;
      height: 320px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &:hover {
          transform: scale(1.05, 1.05);
          transition: 0.3s all ease-in-out;
        }
      }
    }

    .main_focus_title {
      font-family: PingFangSC-Semibold, "Microsoft YaHei";
      position: absolute;
      left: 0;
      bottom: 10px;
      width: 100%;
      min-height: 70px; //    background: rgba(0,0,0,.62);
      padding: 5px 20px;

      span {
        display: inline-block;
        font-size: 20px;
        color: #ffffff;
      }
    }
  }
}

#album_carousel {
  position: relative;
  // padding-bottom: 20px;
  margin: 20px 0 60px;
  min-height: 300px;
  background: #faf9f9;

  .carousel-inner {
    height: 285px;

    .item {
      a {
        display: inline-block;

        img {
          width: 100%;
          height: 180px;
        }

        .album-content {
          padding: 0 15px 10px;
          height: 115px;

          .album-title {
            // height: 36px;
            font-size: 16px;
            line-height: 18px;
            overflow: hidden;
            // position: relative;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .album-desc {
            // height: 40px;
            font-size: 14px;
            overflow: hidden;
            position: relative;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          // .util-words::after {
          //   content: "...";
          //   position: absolute;
          //   bottom: 0;
          //   right: 0;
          //   padding-left: 40px;
          //   background: -webkit-linear-gradient(left, transparent, #fff 55%);
          //   background: -moz-linear-gradient(left, transparent, #fff 55%);
          //   background: -o-linear-gradient(left, transparent, #fff 55%);
          //   background: linear-gradient(to right, transparent, #fff 55%);
          // }
        }
      }
    }
  }

  .carousel-indicators {
    right: 15px;
    left: auto;
    width: auto;
    top: 155px;
  }

  .carousel-indicators li {
    transition: 0.3s all ease-in-out;
    border: 0;
    width: 6px;
    height: 6px;
    margin: 2px;
    background: rgba(255, 255, 255, 0.6);
  }

  .carousel-indicators .active {
    width: 20px;
    height: 6px;
    border: 0;
    margin: 2px;
    background-color: #ffd53f;
  }

  .more-album {
    text-align: center;

    a {
      font-size: 14px;
      color: #2f7dff;
    }
  }
}

// ----------------- 专辑轮播样式调整结束 -----------------
.minor_focus_col {
  padding: 0 5px;

  .minor_focus {
    width: 100%;
    overflow: hidden;

    .minor_focus_block {
      position: relative;
      height: 155px;

      &:hover img {
        transform: scale(1.05, 1.05);
        transition: 0.3s all ease-in-out;
      }

      >a {
        display: inline-block;
        overflow: hidden;
        width: 100%;
        position: relative;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .minor_focus_title {
      font-family: PingFangSC-Semibold, "Microsoft YaHei";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 5px 15px;

      span {
        display: inline-block;
        font-size: 14px;
        color: #fff;
      }
    }

    .minor_focus_block:first-child {
      margin-bottom: 10px;
    }
  }
}

// 轮播遮罩层
.minor_focus_mask {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 50%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 1) 80%);
  opacity: 0.6;

  &:hover {
    opacity: 0.6;
  }
}

.minor_focus_full_mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #27292e;
  opacity: 0.1;
}

// header tab 样式
.header-tab {
  width: 100%;
  margin: 50px 0px 10px;

  ul {
    margin: 0 -20px 15px;
    width: 100%;
    font-size: 0px;

    li {
      display: inline-block;
      padding: 15px 20px 0px;
      box-sizing: border-box;

      .tab-item {
        text-align: center;

        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          font-size: 18px;
          color: #27292e;
        }
      }
    }

    li.active {
      .tab-item {
        a {
          font-weight: bold;
          color: #27292e;
        }
      }
    }
  }

  .gradient-line {
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, rgba(255, 218, 0, 1) 0%, rgba(255, 110, 0, 1) 100%)
  }
}

// ----------------- 筛选 ---------------------
.article-filter {
  line-height: 14px;

  #industry_filter {
    // display: none;
    margin-top: 20px;
    color: #4a4a4a;
    font-size: 1.4rem;
    overflow: hidden;

    .title {
      float: left;
      line-height: 24px;
    }

    >ul {
      width: 100%;
      margin-left: 50px;

      li {
        // margin-right: 40px;
        width: 95px;
        margin-bottom: 15px;
        transition: all 0.3s ease-in-out;
        padding: 0;

        >span {
          display: inline-block;
          padding: 4px 6px;

          &:hover {
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            background-color: #27292e;
            color: #ffffff;
          }

          &.active {
            background-color: #27292e;
            color: #ffffff;
          }
        }

        &._more,
        &._less {
          display: none;
        }

        &.more,
        &.less {
          color: #2F7DFF;

          >span {
            color: #2F7DFF;

            &:hover {
              background-color: transparent;
              color: #2F7DFF
            }
          }
        }

        &.less {
          display: none;
        }
      }
    }
  }

  .article_stage {
    margin-top: 7px;
    position: relative;
    color: #4a4a4a;
    font-size: 1.4rem;
    overflow: hidden;

    .title {
      float: left;
      line-height: 24px;
    }

    ul {
      margin-left: 50px;
      transition: all 0.3s ease-in-out;
      padding: 0;

      li {
        display: inline-block;
        cursor: pointer;
        margin-right: 40px;
        margin-bottom: 15px;
        padding: 4px 6px;

        &:hover {
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          background-color: #27292e;
          color: #ffffff;
        }

        &.active {
          background-color: #27292e;
          color: #ffffff;
        }
      }
    }
  }
}

// ----------------- 文章列表 ---------------------
.articles_list_container {
  margin-top: 35px;

  .article_block {
    height: 180px;
    margin-bottom: 50px;
    overflow: hidden;

    &>div {
      position: relative;
      float: left;
      overflow: hidden;
    }

    .article_img {
      width: 35%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &:hover {
          transform: scale(1.05, 1.05);
          transition: 0.3s all ease-in-out;
        }
      }
    }

    .article_content {
      width: 65%;
      height: 100%;
      padding-left: 20px;

      em {
        color: red;
      }

      h3 {
        margin: 0;
        line-height: 26px;
        font-family: $boldFont;
        font-weight: 500;
        font-size: 19px;
        color: #27292e;

        a {
          color: #333333;

          &:hover {
            color: #4cc1fa;
          }
        }
      }

      .article_digest {
        margin-top: 15px;
        line-height: 20px;
        font-size: 14px;
        color: #9DA0A5;

        a {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          &:hover {
            color: #4cc1fa;
            transition: 0.3s all ease-in-out;
          }
        }
      }

      .article_author {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        padding-left: 20px;

        &>div {
          float: left;
          overflow: hidden;
        }

        .cate-tag {
          display: inline-block;
          margin-right: 30px;
          height: 25px;
          line-height: 25px;
          padding: 0 5px;
          font-size: 14px;
          color: #5D6D94;
          background-color: #EBECED;
        }

        .author_items {
          line-height: 25px;
          font-size: 14px;
          color: #9da0a5;

          a {
            font-size: 16px;
          }

          .author_name::after {
            content: ' ·';
            display: inline-block;
          }

        }
      }
    }
  }

  // 首页文章列表广告
}

.load_more_articles {
  margin: 10px auto 60px;
  width: 140px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #5D6D94;
  font-size: 14px;
  color: #5D6D94;
  cursor: pointer;

  img {
    display: none;
    width: 30px;
    height: 30px;
    object-fit: cover;
  }

  span {
    display: block;
    width: 100%;
    height: 100%;
  }

  div {
    display: none;
  }
}

// ----------------- 右侧栏 ---------------------
.right_block {
  overflow: hidden;

  .right_block_title {
    position: relative;
    overflow: hidden;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    background-color: #F4F4F4;

    .right_block_title_triangle {
      position: absolute;
      left: -7.5px;
      top: -7.5px;
      width: 0;
      height: 0;
      border: 7.5px solid transparent;
      border-right: 7.5px solid #FFB300;
      transform: rotate(45deg);
    }

    .right_block_title_left {
      float: left;
      font-family: $boldFont;
      font-size: 18px;
      color: #27292E;
    }

    .right_block_title_right {
      float: right;
      font-size: 12px;
      color: #6C7179;

      .right_icon {
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url('../imgs/icon/right_icon.png') no-repeat;
        background-size: contain;
        position: relative;
        top: 2px;
      }
    }
  }

  .right_block_content {
    .index-flash-list {
      margin: 20px 0;

      .index-flash-item {
        position: relative;
        margin-bottom: 25px;

        &-circle {
          position: absolute;
          top: 6px;
          left: 0;
          width: 10px;
          height: 10px;
          border: 0.5px solid #F5B53F;
          border-radius: 50%;

          &-inner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 4px;
            height: 4px;
            border-radius: 50%;
            border-radius: 50%;
            background-color: #F5B53F;
          }
        }

        &-content {
          margin-left: 20px;

          &-up {
            margin-bottom: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 20px;
            font-size: 14px;
            color: #27292E;
            cursor: pointer;
          }

          &-middle {
            line-height: 26px;
            font-size: 13px;
            color: rgba(108, 113, 121, 1);
          }

          &-down {
            margin-top: 10px;
            line-height: 17px;
            font-size: 12px;
            color: #9DA0A5;

            &-from::before {
              content: '·';
              display: inline-block;
              line-height: 17px;
            }
          }
        }
      }
    }

    .hot-article-list {
      margin: 20px 0 60px;

      .hot-article-item {
        margin-bottom: 25px;
        overflow: hidden;

        &-left {
          float: left;
          position: relative;

          &-index {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 18px;
            height: 18px;
            line-height: 18px;
            text-align: center;
            font-size: 10px;
            background-color: #27292E;
            color: #fff;
          }

          &-block {
            width: 30px;
            padding-top: 100%;
          }

          &-img {
            width: 100px;
            height: 72px;
            margin-right: 10px;
            object-fit: cover;
          }
        }

        &-right {
          margin-left: 30px;
          line-height: 20px;
          font-size: 15px;

          a {
            color: #27292E;
          }
        }
      }
    }

    // 活动模块样式
    .active-article-list {
      margin: 20px 0 60px;

      .active-article-item {
        margin-bottom: 25px;
        overflow: hidden;

        &-left {
          float: left;
          position: relative;

          &-img {
            width: 100px;
            height: 72px;
            margin-right: 10px;
            object-fit: cover;
          }
        }

        &-right {
          margin-left: 30px;
          line-height: 20px;
          font-size: 15px;

          a {
            color: #27292E;
          }
        }
      }

      .active-article-index-item {
        margin-bottom: 25px;
        overflow: hidden;

        &-img {
          width: 100%;
          height: 180px;
          background-size: contain;
          background-position: center;
          background-color: lightgray;
        }

        &-title {
          margin-top: 10px;
          line-height: 20px;
          font-size: 15px;
          overflow: hidden;
          text-overflow: ellipsis; //当内联内容溢出块容器时，将溢出部分替换为(...)
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; //限制的行数

          a {
            color: #27292E;
          }
        }
      }
    }

    .index-project-list-container {
      padding-bottom: 1px;
      background-color: #FAF9F9;
      margin: 0px 0 60px;
    }

    .index-project-list {
      padding: 20px 0;

      .index-project-item {
        margin-bottom: 25px;
        padding: 0 15px;
        overflow: hidden;

        &-logo {
          float: left;
          width: 55px;
          height: 55px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        &-content {
          margin-left: 70px;

          &-up {
            margin-top: 6px;
            font-size: 15px;
            font-family: $boldFont;
            font-weight: 500;
            color: rgba(39, 41, 46, 1);
            line-height: 21px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &-down {
            margin-top: 5px;
            line-height: 17px;
            font-size: 12px;
            color: #9DA0A5;
          }
        }
      }
    }
  }

  .right_block_ad {
    position: relative;
    margin: 30px 0;
    width: 100%;
    padding-top: 60%;
    background-color: lightgray;
    // background: url('/imgs/advertisement/index_right.jpg') no-repeat;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &-tag {
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 12px;
      background-color: rgba(0, 0, 0, 0.3);
      color: #fff;
    }
  }

  .right_block_more {
    margin: 5px auto 30px;
    width: 82px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 0.5px solid #5D6D94;
    font-size: 12px;
    color: #5D6D94;
  }
}

.left_block {
  overflow: hidden;

  .left_block_title {
    position: relative;
    overflow: hidden;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    background-color: #F4F4F4;

    .left_block_title_triangle {
      position: absolute;
      left: -7.5px;
      top: -7.5px;
      width: 0;
      height: 0;
      border: 7.5px solid transparent;
      border-right: 7.5px solid #FFB300;
      transform: rotate(45deg);
    }

    .left_block_title_left {
      float: left;
      font-family: $boldFont;
      font-size: 18px;
      color: #27292E;
    }

    .left_block_title_right {
      float: right;
      font-size: 12px;
      color: #6C7179;
    }
  }
}

//记者页CSS
.his_article_nav {
  .nav-tabs {
    width: 320px;
    margin: 0 auto 25px auto;
    border: 0;

    &>li {
      margin-bottom: -4px;
      margin-left: 30px;
      margin-right: 30px;

      &.active {
        &>a {
          border: 0;
          border-bottom: 4px solid #ffd53f;
          color: #ffd53f;

          &:focus {
            border: 0;
            border-bottom: 4px solid #ffd53f;
          }
        }
      }

      &>a {
        color: #333333;
        border: 0;

        &:focus {
          border: 0;
          border-bottom: 4px solid #ffd53f;
        }

        &:hover {
          background: initial;
          transition: none;
        }
      }
    }
  }
}

.journalist_right_container {
  margin-top: 30px;
}

#his_articles {
  margin-top: 0;

  .articles-num {
    padding-top: 20px;
  }
}

.journalist_container {
  position: relative;
  margin-top: -20px;
  padding-top: 25px;
  padding-bottom: 10px;
  text-align: center;
  color: #fff;
  font-size: 18px;

  .journalist_bg {
    position: absolute;
    z-index: -1;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .journalist_content {
    .avatar {
      width: 140px;
      height: 140px;
      margin: 0 auto 15px auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &>div {
      margin-bottom: 15px;
    }

    .name {
      span {
        font-size: 32px;
      }
    }

    .industries {
      span {
        padding: 2px 8px;
        border: 1px solid white;
        border-radius: 4px;
        margin: 10px;
        display: inline-block;
      }
    }

    .contact_journalist_btn {
      width: 100px;
      height: 40px;
      text-align: center;
      color: #333333;
      font-size: 16px;
      border: 0;
      background: #ffd53f;

      &:hover {
        background: #ffd53f;
      }

      &:focus {
        outline: none;
      }
    }

    #journalist_desc {
      max-width: 1170px;
      padding-left: 15px;
      padding-right: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 auto;
    }
  }
}

.columnist-container {
  position: relative;
  height: 220px;
  margin-top: -20px;

  .columnist-bg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .columnist-info-wrap {
    overflow: hidden;
    margin-top: 30px;

    .columnist-avatar {
      float: left;

      img {
        width: 130px;
        height: 130px;
      }
    }

    .columnist-info {
      margin-left: 153px;
      color: #ffffff;

      .columnist-name {
        font-family: $boldFont;
        font-size: 24px;
        line-height: 33px;
        display: flex;
        align-items: center;

        .columnist-title {
          font-family: PingFangSC-Regular;
          display: inline-block;
          width: 110px;
          height: 20px;
          margin-left: 12px;
          line-height: 20px;
          font-size: 12px;
          text-align: center;
          color: rgba(39, 41, 46, 1);
          background: rgba(252, 219, 0, 1);
          border-radius: 10px;
        }
      }

      .columnist-position {
        line-height: 42px;
        font-size: 16px;
        color: rgba(235, 236, 237, 1);
      }

      .columnist-desc {
        line-height: 22px;
        font-size: 16px;
        color: rgba(157, 160, 165, 1);
      }
    }
  }
}

.journalist_right_container {
  position: relative;
  width: 280px;
  margin-top: 60px;
  padding: 40px 0 20px;
  border: 1px solid #dfdfe0;
  text-align: center;
  font-size: 14px;
  color: rgba(108, 113, 121, 1);

  .journalist-wechat-icon {
    width: 65px;
    height: 65px;
    margin-top: -110px;
  }

  .journalist_qrcode {
    img {
      width: 160px;
      height: 160px;
      margin-bottom: 10px;
    }

    p {
      padding-bottom: 10px;
      line-height: 20px;
    }
  }
}

#articles_pages_container {
  display: none;
  z-index: 100;
}

//覆盖Jpage默认样式
.articles_pages_container {
  background: #eeeeee;
  padding: 15px;
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
  position: relative;

  &.fixed {
    margin: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.articles_page {
  display: inline-block;
  margin: 0;

  a {
    margin: 0;
    color: #666666;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;

    &:hover {
      cursor: pointer;
    }
  }

  a.jp-current {
    background: #64a6bd;
    border: 0;
    color: #fff;
    line-height: 28px;
  }

  a.jp-previous {
    color: #666666;
    padding-left: 15px;
    padding-right: 15px;
    border-right: 1px solid #c2c2c2;
  }

  a.jp-next {
    padding-left: 15px;
    padding-right: 15px;
    color: #666666;
    border-left: 1px solid #c2c2c2;
  }

  // a.jp-previous:hover,a.jp-next:hover{
  //     cursor: pointer;
  // }
  a.jp-first {
    color: #666666;
    padding-left: 0;
    padding-right: 15px; //        border-right: 1px solid #c2c2c2;
    border-right: 0;
    position: absolute;
    left: 25px;
  }

  a.jp-last {
    padding-left: 15px;
    padding-right: 15px;
    border-left: 1px solid #c2c2c2;
    color: #666666;
  }

  a.jp-disabled {
    color: #999999;
  }
}

.page-jump-container {
  //    display: inline-block;
  position: absolute;
  top: 15px;
  right: 25px;
}

.page-jump-input {
  width: 50px;
  height: 30px;
  border: 1px solid #c2c2c2;
  font-size: 14px;
  background: #eeeeee;
  text-align: center;
  border-radius: 0;

  &:focus {
    outline: none;
  }
}

input.page-jump-input {
  border-radius: 0;
}

.page-jump-btn {
  border: 0;
  background: #eeeeee;
  text-align: center;
  color: #666666;
  font-size: 14px;

  &:focus {
    border: 0;
    outline: none;
  }

  &:hover {
    background: inherit;
  }
}

//覆盖jpages默认样式 end
@media (max-width: 1199px) {
  .container {
    padding: 0;
  }

  .left_container {
    width: 684px;
  }

  .right_container {
    width: 235px;
    margin-left: 51px;
  }

  .carousel-inner .item {
    // height: 302px;
    height: 320px;
  }

  a[href="#main_focus_img"] {
    line-height: 320px;
  }

  .main_focus {
    &>div {
      height: 320px;
    }
  }

  .minor_focus {
    // width: 480px;
    float: left;
    overflow: hidden;

    .minor_focus_block {
      // width: 235px;
      height: 146px;
    }

    .minor_focus_block:first-child {
      margin-bottom: 8px;
    }
  }

  .main_focus_title {
    min-height: 60px;

    span {
      font-size: 16px;
    }
  }

  .minor_focus_title {
    span {
      font-size: 14px;
    }
  }

  .article_block {
    height: 162px;

    .article_content {
      h3 {
        line-height: 24px;
        font-size: 18px;
      }
    }
  }

  //记者页面
  .journalist_container {
    font-size: 16px;

    .journalist_content {
      .avatar {
        width: 80px;
        height: 80px;
      }

      .name {
        span {
          font-size: 22px;
        }
      }

      .contact_journalist_btn {
        width: 100px;
        height: 40px;
        font-size: 14px;
        border: 0;
      }

      #journalist_desc {
        max-width: 970px;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 991px) {
  .left_container {
    width: 512px;
  }

  .right_container {
    width: 208px;
    margin-left: 30px;
  }

  //隐藏轮播图小点
  .carousel-indicators {
    display: none;
  }

  .carousel-inner .item {
    height: 320px;
  }

  a[href="#main_focus_img"] {
    line-height: 320px;
  }

  .main_focus {
    &>div {
      // width: 370px;
      height: 320px;
    }
  }

  .minor_focus {
    display: none;
  }

  .journalist_right_container {
    margin-top: 20px;
  }

  .main_focus_title {
    min-height: 60px;

    span {
      font-size: 18px;
    }
  }

  .minor_focus_title {
    span {
      font-size: 14px;
    }
  }

  .article_block {
    height: 120px;
    margin-bottom: 20px;
    padding-top: 20px;
  }

  .article_content {
    h3 {
      line-height: 18px;
      font-size: 14px;
    }

    .article_digest {
      font-size: 12px;
      margin-top: 3px;
      line-height: 16px;
    }

    .article_author {
      img {
        width: 24px;
        height: 24px;
      }

      span {
        line-height: 24px;
        font-size: 12px;
      }

      .author_items {
        font-size: 12px;

        span {
          font-size: 12px;
        }
      }
    }
  }

  #industry_status {
    display: none;
  }

  .right_block {
    margin-bottom: 20px;
  }

  //记者页
  .journalist_container {
    font-size: 14px;

    .journalist_content {
      .avatar {
        width: 72px;
        height: 72px;
      }

      .name {
        span {
          font-size: 16px;
        }
      }

      .contact_journalist_btn {
        width: 100px;
        height: 30px;
        font-size: 14px;
        border: 0;
      }

      #journalist_desc {
        max-width: 750px;
        margin: 0 auto;
      }
    }
  }

  //分页
  .articles_page {
    display: block;
    position: initial;
    text-align: center;
  }

  .page-jump-container {
    display: block;
    text-align: center;
    position: initial;
    margin-top: 15px;
  }

  .articles_page {
    a.jp-first {
      position: initial;
      border-right: 1px solid #c2c2c2;
    }
  }
}

@media (max-width: 767px) {
  .container {
    width: 100%;
  }

  .left_container {
    width: 100%;
  }

  .main_focus {
    width: 100%;

    &>div {
      width: 100%;
    }
  }

  .main_focus_title {
    min-height: 50px;
    padding: 5px 12px 20px 12px;

    span {
      font-size: 16px;
    }
  }

  .minor_focus {
    display: none;
  }

  .article_stage,
  #industry_filter {
    display: none;
  }

  .left_container {
    width: 100%;
    padding: 15px;
  }

  .article_block {
    height: 140px;
    padding-top: 15px;
    margin-bottom: 15px;
  }

  .article_content {
    h3 {
      line-height: 20px;
      font-size: 15px;
    }

    .article_digest {
      font-size: 12px;
      margin-top: 3px;
      line-height: 16px;
    }

    .article_digest {
      display: none;
    }

    .article_author {
      &>div {
        line-height: 12px;
      }

      &>div:nth-child(1) {
        display: none;
      }

      span {
        line-height: 22px;
      }

      .author_items {
        font-size: 12px;
        line-height: 22px;
        margin-left: 0;

        span {
          font-size: 12px;
          line-height: 22px;
        }
      }
    }
  }

  .right_container {
    display: none;
  }

  .search_rlt_page {
    padding-top: 15px;

    .search_rlt_title {
      margin-bottom: 5px;
      display: block;
    }
  }

  .journalist_container {
    margin-top: 0;
  }

  .journalist_container {
    .journalist_content {
      &>div {
        margin-bottom: 5px;
      }
    }
  }
}